<template>
  <div class="back">
    <van-form class="from-bottom" @submit="onSubmit" label-width="160" input-align="right">
      <van-cell-group>
        <van-field
          v-model="form.dmfdmTyxydm"
          name="dmfdmTyxydm"
          label="统一信用代码："
          placeholder="请输入统一信用代码"
        />
        <van-field
          v-model="form.dmfdmSwqymc"
          name="dmfdmSwqymc"
          label="企业名称"
          placeholder="请输入企业名称"
        />
        <van-field
          is-link
          readonly
          clickable
          v-model="form.rbThyy"
          name="rbThyy"
          @click="showDio(1)"
          label="退回原因："
          placeholder="请选择退回原因"
        />
        <van-field
          is-link
          readonly
          clickable
          v-model="form.rbYthgsd"
          @click="showDio(2)"
          name="rbYthgsd"
          label="应退回地址："
          placeholder="请选择应退回地址"
        />
      </van-cell-group>

      <div class="submit-btn">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <!-- 意见弹窗 -->
    <van-dialog
      v-model="show1"
      show-cancel-button
      @cancel="message = ''"
      @confirm="form.rbThyy = message"
    >
      <template>
        <van-field v-model="message" rows="1" type="textarea" placeholder="请输入留言" />
      </template>
    </van-dialog>
    <!-- 退回地址 -->
    <van-popup safe-area-inset-bottom v-model="show2" position="bottom" :style="{ height: '35%' }">
      <van-picker
        title="请选择退回地址"
        @confirm="confirm2"
        @cancel="show2 = false"
        show-toolbar
        :columns="positionList"
        :default-index="2"
      />
    </van-popup>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { editBack, getStreetOrgInfo } from '../api/home';
export default {
  data() {
    return {
      show1: false,
      show2: false,
      message: '',
      positionList: [],

      form: {
        dmfdmSwqymc: '', //企业名称
        dmfdmTyxydm: '', //统一社会信用代码
        dmfdmZcdz: '', //企业地址
        rcDmfdmUuid: '', //企业合并表UUID
        rcUuid: '', //认领企业表UUID
        rbThyy: '', // 退回原因
        rbYthgsdUuid: '',
        rbYthgsd: '', // 退回地址
      },
    };
  },
  beforeRouteEnter(to, form, next) {
    next(vm => {
      Object.assign(vm.form, JSON.parse(vm.$route.query.item));
      vm.getPositionList();
    });
  },
  methods: {
    getPositionList() {
      getStreetOrgInfo()
        .then(res => {
          this.positionList = res.data.map(item => {
            return {
              text: item.crorgName,
              value: item.crorgUuid,
            };
          });
        })
        .catch(err => {
          Toast.fail('退回地址列表获取失败！');
        });
    },
    confirm2(item) {
      this.form.rbYthgsd = item.text;
      this.form.rbYthgsdUuid = item.value;
      this.show2 = false;
    },
    // 退回原因填写
    showDio(i) {
      document.activeElement.blur();
      this['show' + i] = true;
    },
    onSubmit() {
      if (this.form.rbThyy.length == 0 || this.form.rbYthgsd.length == 0) {
        Toast.fail('请输入完整信息！');
        return false;
      }

      let that = this;
      let data = {
        rbRcUuid: this.form.rcUuid,
        rbThyy: this.form.rbThyy,
        rbYthgsdUuid: this.form.rbYthgsdUuid,
        rbYthgsd: this.form.rbYthgsd,
      };
      editBack(data).then(res => {
        Toast.success({
          type: 'success',
          message: '操作成功',
          onClose() {
            that.$router.go(-1);
          },
        });
        // console.log('🚀 ~ file: back.vue ~ line 73 ~ onSubmit ~ res', res);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .van-cell__title {
  position: relative;
  padding-left: 10px;
}
::v-deep .van-cell__title::before {
  display: block;
  content: '*';
  color: red;
  left: 0px;
  position: absolute;
  z-index: 3;
}
::v-deep textarea {
  height: 100px !important;
}
::v-deep .from-bottom {
  padding-bottom: 1.2rem;
}
.back {
  min-height: 100vh;
  background: #f6f8fe;
  .submit-btn {
    // background: #fff;
    position: fixed;
    left: 0.32rem;
    right: 0.32rem;
    bottom: 0.35rem;
  }
}
</style>
